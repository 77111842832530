<template>
  <section id="pawiwahan">
    <div class="container">
      <div class="heading text-center with-border" data-aos="fade-down">
        <h2 class="title text-uppercase text-teal-dark">3 Bulanan</h2>
        <!-- <div class="sub-title">We Are Getting Married</div> -->
      </div>

      <div class="row justify-content-center pawiwahan pad-top-30">
        <div
          class="col-md-6 col-lg-5 mar-bottom-30"
          data-aos="zoom-in-right"
          data-aos-delay="150"
        >
          <div class="picture">
            <img :src="$parent.wedding.man.photo" />
          </div>
          <div class="name">{{ $parent.wedding.man.name }}</div>
          <p class="text-muted mb-0">
            {{ $parent.wedding.man.description }} dari pasangan
          </p>
          <p class="font-weight-bold mb-0">{{ $parent.wedding.man.parents }}</p>
          <p class="font-weight-lighter mb-0">
            {{ $parent.wedding.man.address }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GetOffsite from "@/libs/offset";

export default {
  computed: {
    y() {
      return this.$root.scroll.Y;
    }
  },
  watch: {
    y() {
      const height = window.innerHeight;

      this.$el.querySelectorAll(".picture").forEach(item => {
        const itemOffset = GetOffsite(item);
        const center = itemOffset.top - height / 2 + item.offsetHeight / 2;
        const pos = center - this.y;
        const offset = item.offsetHeight;

        if (pos < offset && pos > offset * -1) {
          let point = pos / offset;
          let positivePoint = point < 0 ? point * -1 : point;
          let scale = 1 + 0.1 - positivePoint / 10;
          let rotate = (1 - positivePoint) * -1;

          item.style.transform = `scale(${scale}) rotate(${rotate}deg)`;
        }
      });
    }
  }
};
</script>
